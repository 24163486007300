import React, { useEffect } from "react";
import "./App.css";
import moreLogo from "./img/logo-vg.svg";
import playStoreBadge from "./img/play-store-badge.svg";
import appStoreBadge from "./img/app-store-badge.svg";
import bgImager from "./img/bg-image.png";
import bgImagerM from "./img/bg-m.png";
import { isMobile, isIOS, isAndroid } from "react-device-detect";

function App(props) {
  const playStoreURL =
    "https://play.google.com/store/apps/details?id=in.moreretail.grocery";
  const [playStoreTrackingURL, setPlayStoreTrackingURL] =
    React.useState(playStoreURL);

  const appStoreURL =
    "https://apps.apple.com/in/app/more-grocery-delivery/id6444301593";
  const [appStoreTrackingURL, setAppStoreTrackingURL] =
    React.useState(appStoreURL);

  const createPlayStoreTrackingURL = (playStoreURL, currentURLSearchParams) => {
    const playStorePossibleParams = [
      "utm_source",
      "utm_medium",
      "utm_term",
      "utm_content",
      "utm_campaign",
      "gclid",
    ];

    const playStoreTrackingParams = encodeURIComponent(
      playStorePossibleParams
        .map((possibleParam) => {
          if (currentURLSearchParams.has(possibleParam)) {
            return (
              possibleParam + "=" + currentURLSearchParams.get(possibleParam)
            );
          }
          return null;
        })
        .filter((currentURLSearchParams) => currentURLSearchParams)
        .join("&")
    );

    if (playStoreTrackingParams) {
      return playStoreURL + "&referrer=" + playStoreTrackingParams;
    }

    return playStoreURL;
  };

  const createAppStoreTrackingURL = (appStoreURL, currentURLSearchParams) => {
    // pt stands for Provider Token. It is a constant value for More Retail on the App Store.
    const pt = "124171430";
    // mt stands for Media Type. The value 8 corresponds means the link points to the media type of Mobile Software Applications aka iOS apps.
    const mt = "8";
    // We use utm_campaign as an alias for ct or Campaign Token.
    if (currentURLSearchParams.has("utm_campaign")) {
      return (
        appStoreURL +
        "?pt=" +
        pt +
        "&ct=" +
        currentURLSearchParams.get("utm_campaign") +
        "&mt=" +
        mt
      );
    }

    return appStoreURL;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    setPlayStoreTrackingURL(createPlayStoreTrackingURL(playStoreURL, params));
    setAppStoreTrackingURL(createAppStoreTrackingURL(appStoreURL, params));

    if (isAndroid) {
      window.location.assign(playStoreTrackingURL);
    } else if (isIOS) {
      window.location.assign(appStoreTrackingURL);
    }
  }, [playStoreTrackingURL, appStoreTrackingURL, playStoreURL, appStoreURL]);

  return (
    <section className="flex h-screen bg">
      <section className="container w-screen m-auto px-0 lg:px-2">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="basis-full lg:basis-5/12">
            <div className="alg-text">
              <div className="mt-4 lg:mt-0 flex justify-center lg:justify-start">
                <img
                  className="h-16 lg:h-20 w-auto"
                  src={moreLogo}
                  alt="logo"
                />
              </div>
              <h1 className="text-3xl px-2 font-semibold	sm:px-0 lg:px-0 lg:text-5xl lg:leading-snug	 my-8 text-slate-700">
                The entire More store at your door, in as early as 2 hours
              </h1>
              <div className="my-4 flex gap-2 justify-center lg:justify-start">
                <a
                  href={playStoreTrackingURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="h-10 lg:h-12 w-auto"
                    src={playStoreBadge}
                    alt="Get it on Google Play"
                  />
                </a>
                <a
                  href={appStoreTrackingURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="h-10 lg:h-12 w-auto"
                    src={appStoreBadge}
                    alt="Download on the App Store"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="basis-full lg:basis-7/12">
            <div className={""}>
              <img
                src={isMobile ? bgImagerM : bgImager}
                className={"object-contain"}
                alt="Banner"
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
export default App;
